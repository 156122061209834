import { useElectric } from "@/electric/ElectricWrapper";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { UxContext } from "@/models/UxStateProvider";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { Box, IconButton, List, Stack } from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import WorkspaceChannelListItem from "./WorkspaceChannelListItem";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";

export default function WorkspaceDmList() {
	const { toggleDmModalOpen } = useContext(UxContext);
	const { myAccount } = useContext(MyAccountContext);

	const { db } = useElectric();
	const { currentWorkspaceId: workspaceId } = useContext(WorkspaceContext);

	const { results: myMembership } = useLiveQuery(
		db.workspace_membership.liveFirst({
			where: {
				workspaceId: workspaceId,
				accountId: myAccount?.id,
				status: "active",
			},
		}),
	);

	const { results: feedLatestActivity } = useLiveQuery(() => {
		if (!workspaceId || !myMembership?.id) return;
		return db.liveRaw({
			sql: `
			SELECT feed.id, feed.title,
			MAX(
				MAX(coalesce(item.createdAt, '0')),
				coalesce(feed.updatedAt, '0'),
				coalesce(feed.latestActivity, '0')
			) as latestActivity

			FROM feed
			JOIN item ON feed.id = item.feedId 
			JOIN permission ON feed.id = permission.feedId

			WHERE feed.workspaceId = ?
			AND feed.isDm = 1
			AND permission.workspace_membershipId = ?
			AND permission.enabled = 1
			AND permission.name = ?

			GROUP BY feed.id, feed.title
			ORDER BY latestActivity DESC`,
			args: [workspaceId, myMembership?.id, "read"],
		});
	}, [workspaceId, myMembership?.id]) as {
		results: { id: string; title: string; latestActivity: string }[];
		error: any;
	};

	const { results: emptyFeeds } = useLiveQuery(() => {
		if (!workspaceId || !myMembership?.id) return;
		return db.liveRaw({
			sql: `
			SELECT 
			feed.id, 
			feed.title, 
			MAX(
				coalesce(feed.updatedAt, '0'),
				coalesce(feed.latestActivity, '0')
			) as latestActivity
			FROM feed
			JOIN permission ON feed.id = permission.feedId

			LEFT OUTER JOIN item ON feed.id = item.feedId
			WHERE feed.workspaceId = ?
			AND feed.isDm = 1
			AND permission.workspace_membershipId = ?
			AND permission.enabled = 1
			AND permission.name = ?
			AND item.id IS NULL
			ORDER BY latestActivity DESC
			`,
			args: [workspaceId, myMembership?.id, "read"],
		});
	}, [workspaceId, myMembership?.id]) as {
		results: { id: string; title: string; latestActivity: string }[];
		error: any;
	};

	const workSpaceFeeds = (feedLatestActivity || []).concat(emptyFeeds)?.sort(
		(feedA, feedB) =>
			// sort the feed list descending after merging them
			+new Date(feedB?.latestActivity) - +new Date(feedA.latestActivity),
	);

	return (
		<Stack
			sx={{
				flexGrow: 1,
				pb: 1.5,
				pl: 2,
				pr: 0.75,
				height: "100%",
			}}
		>
			<Box
				key={`${workspaceId}-dm-channel-controls`}
				sx={{
					display: "flex",
					justifyContent: "space-between",
					pt: 2.5,
				}}
			>
				<Box
					sx={{
						width: "100%",
						gap: 1.5,
						fontWeight: 700,
						display: "flex",
						alignItems: "center",
						fontSize: "1.25rem",
						pb: 1,
					}}
				>
					Direct Messages
				</Box>
				<IconButton onClick={toggleDmModalOpen}>
					<AddCommentOutlinedIcon sx={{ transform: "scaleX(-1)" }} />
				</IconButton>
			</Box>
			<List key={`${workspaceId}-dm-channels`} className="scrollable-content">
				{workSpaceFeeds?.map((feed, index) => (
					<WorkspaceChannelListItem
						feedId={feed?.id}
						key={feed?.id ?? `${index}-${workspaceId}`}
						latestActivity={feed?.latestActivity}
					/>
				))}
			</List>
		</Stack>
	);
}
