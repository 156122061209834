import Locator from "@/locator";
import { Box, MenuItem } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import type React from "react";
import { useContext, useState } from "react";
import ListIcon from "@/components/Icons/ListIcon";
import { useElectric } from "@/electric/ElectricWrapper";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";
import { useNavigate, useParams } from "react-router-dom";
import type { channelItem } from "@/components/WorkspaceChannelList/WorkspaceChannelListContainer";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import LoginIcon from "@mui/icons-material/Login";

interface Props {
  group: channelItem;
  joinedGroup: boolean;
  groupHasUnreads: boolean;
  handleClose: () => void;
  joinAllChannelList: boolean;
  workspaceMembershipId: string;
}

export default function GroupContextMenu({
  workspaceMembershipId,
  group,
  joinAllChannelList,
  joinedGroup,
  groupHasUnreads,
  handleClose,
}: Props) {
  const { ampli } = useContext(TrackingContext);
  const { fetchNextActiveChannel } = useContext(LiveQueryContext);
  const { db } = useElectric();
  const { workspaceId, feedId } = useParams();
  const navigate = useNavigate();
  const { accountEvent } = useContext(ActionContext);
  const { joinFeedGroup, leaveFeedGroup } = useContext(DataContext);
  const [disabled, setDisabled] = useState<boolean>(false);
  const feedIdIsActiveInGroup = group.channels
    .map((channel) => channel.id)
    .includes(feedId);

  const joinGroup = async () => {
    setDisabled(() => true);
    ampli.groupJoin({ workspaceId });
    await joinFeedGroup({
      workspaceId,
      feedGroupId: group.groupId,
    });
    setDisabled(() => false);
    handleClose();
  };

  const leaveGroup = async () => {
    setDisabled(() => true);
    ampli.groupLeave({ workspaceId });
    await leaveFeedGroup({
      workspaceId,
      feedGroupId: group.groupId,
    });
    const url = feedIdIsActiveInGroup ? await fetchNextActiveChannel() : null;
    setDisabled(() => false);
    if (url) {
      navigate(url);
    }
    handleClose();
  };

  const markAllAsRead = async () => {
    setDisabled(() => true);

    const sql = `
    select
     item.id, item.feedId, item.groupId, item.unread,
     feed.title,
     feed_group_membership.groupId as feedGroupId,
     permission.workspace_membershipId as joined
     from item
     join feed on feed.id = item.feedId
     JOIN permission  ON feed.id = permission.feedId AND permission.workspace_membershipId = $1 AND permission.name = 'read' AND permission.enabled = true
     LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
     LEFT JOIN feed_group on feed_group.id = feed_group_membership.groupId
     where
     unread = 1
     AND feed_group_membership.groupId = $2
     GROUP by item.id
    `;
    const feedItems = await db.raw({
      sql,
      args: [workspaceMembershipId, group.groupId],
    });

    if (feedItems?.length > 0) {
      ampli.groupMarkAllRead({ workspaceId });
      for (const feedItem of feedItems) {
        accountEvent("Marked Feed as Read", {
          feedId: feedItem.feedId,
        });
      }
    }
    setDisabled(() => false);
    handleClose();
  };

  const joinAllChannels = async () => {
    setDisabled(() => true);
    ampli.groupJoin({ workspaceId });
    await joinFeedGroup({
      workspaceId,
      feedGroupId: group.groupId,
    });
    setDisabled(() => false);
    handleClose();
  };

  return (
    <div className="new-channel-item-context-menu">
      {joinedGroup && groupHasUnreads && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.groups.markAllAsRead}
          disabled={disabled}
          onClick={markAllAsRead}
        >
          <Box className="new-channel-context-button">
            <DraftsOutlinedIcon />
          </Box>
          <Box>Mark all as Read</Box>
        </MenuItem>
      )}

      {joinAllChannelList && joinedGroup && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.groups.joinAll}
          disabled={disabled}
          onClick={joinAllChannels}
        >
          <Box className="new-channel-context-button">
            <LoginIcon />
          </Box>
          <Box>Join all channels</Box>
        </MenuItem>
      )}

      {!joinedGroup && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.groups.join}
          disabled={disabled}
          onClick={joinGroup}
        >
          <Box className="new-channel-context-button">
            <LoginIcon />
          </Box>
          <Box>Join Group</Box>
        </MenuItem>
      )}

      {joinedGroup && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.groups.leave}
          disabled={disabled}
          onClick={leaveGroup}
        >
          <Box className="new-channel-context-button">
            <LogoutIcon />
          </Box>
          <Box>Leave Group</Box>
        </MenuItem>
      )}
    </div>
  );
}
