import InvalidUser from "@/components/InvalidUser";
import InviteWorkspaceUsersLink from "@/components/Workspace/InviteWorkspaceUsersLink";
import WorkspaceInvitesTable from "@/components/Workspace/WorkspaceInvitesTable";
import WorkspaceMembersTable from "@/components/Workspace/WorkspaceMembersTable";
import { useElectric } from "@/electric/ElectricWrapper";
import Locator from "@/locator";
import { DataContext } from "@/models/DataProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import UserManagementContextProvider from "@/models/UserManagementContextProvider";
import { UxContext } from "@/models/UxStateProvider";
import { Button, Stack, useTheme } from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function WorkspaceUserManagement() {
  const { db } = useElectric();
  const { availableWorkspaceRoles } =
    useContext(DataContext);
  const { myAccount } = useContext(MyAccountContext);
  const { setNavTitle } = useContext(UxContext);
  const params = useParams();
  const workspaceId = params?.workspaceId as string;
  const theme = useTheme();

  const [showMembersTable, setShowMembersTable] = useState<boolean>(true);
  const [showPendingInvites, setShowPendingInvites] = useState<boolean>(false);

  const { results: isAdmin } = useLiveQuery(
    db.workspace_membership.liveFirst({
      where: {
        workspaceId,
        accountId: myAccount?.id,
        status: "active",
        role: "admin",
      },
    }),
  );

  const { results: workspaceMemberCountResponse } = useLiveQuery(() => {
    if (!workspaceId) return;
    return db.liveRaw({
      sql: `
			SELECT COUNT(workspace_membership.accountId) as memberCount
			FROM workspace_membership
            WHERE workspace_membership.workspaceId = ?
			AND workspace_membership.status = 'active'`,
      args: [workspaceId],
    });
  }, [workspaceId]);

  const { results: workspaceInviteCountResponse } = useLiveQuery(() => {
    if (!workspaceId) return;
    return db.liveRaw({
      sql: `
			SELECT COUNT(direct_ws_invitation.workspaceMembershipId) as inviteCount
			FROM direct_ws_invitation
			JOIN workspace_membership ON direct_ws_invitation.workspaceMembershipId = workspace_membership.id
			WHERE workspace_membership.status = 'active'
			AND workspace_membership.workspaceId = ?
			AND direct_ws_invitation.claimedBy is null;
			`,
      args: [workspaceId],
    });
  }, [workspaceId]);

  const workspaceMemberCount = workspaceMemberCountResponse?.[0]?.memberCount;
  const workspaceInviteCount = workspaceInviteCountResponse?.[0]?.inviteCount;

  const showMembersView = () => {
    setShowMembersTable(true);
    setShowPendingInvites(false);
  };

  const showInvitesView = () => {
    setShowMembersTable(false);
    setShowPendingInvites(true);
  };

  useEffect(() => {
    setNavTitle("Manage members");
  }, [setNavTitle]);

  return (
    <>
      <InvalidUser validRoles={[availableWorkspaceRoles?.get("admin")?.role]} />
      <UserManagementContextProvider>
        {/* <InviteToWorkspaceModal /> */}
        <Stack
          sx={{
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
            pt: 8,
            px: 2,
          }}
        >
          <Stack
            sx={{
              width: "100%",
              maxWidth: 1300,
              height: "auto",
              pb: 4,
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { xs: "flex-start", sm: "space-between" },
              gap: 2,
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: { xs: "space-bewteen", sm: "flex-start" },
                gap: { xs: 1, sm: 3 },
                height: 80,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Button
                onClick={showMembersView}
                aria-label={Locator.workspaceNav.members.membersTab}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 2,
                  background: showMembersTable
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
                  maxWidth: { xs: "calc(50% - 8px)", sm: 150 },
                  height: "100%",
                  textTransform: "none",
                  fontWeight: 700,
                  flex: "0 0 auto",
                }}
              >
                {workspaceMemberCount}{" "}
                {workspaceMemberCount === 1 ? "Member" : "Members"}
              </Button>
              <Button
                onClick={showInvitesView}
                aria-label={Locator.workspaceNav.members.pendingInvitesTab}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 2,
                  background: showPendingInvites
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
                  textTransform: "none",
                  fontWeight: 700,
                  maxWidth: { xs: "calc(50% - 8px)", sm: 200 },
                  height: "100%",
                  flex: "0 0 auto",
                }}
              >
                {workspaceInviteCount > 0 ? workspaceInviteCount : ""}
                {" Pending "}
                {workspaceInviteCount === 1 ? "invite" : "invites"}
              </Button>
            </Stack>
            <Stack
              sx={{
                flexDirection: "row",
                gap: 3,
                alignItems: "center",
              }}
            >
              {/* <Typography fontWeight="700">xxx remaining licenses</Typography> */}
              <InviteWorkspaceUsersLink sx={{ minWidth: 200 }} />
            </Stack>
          </Stack>
          {showMembersTable && !showPendingInvites ? (
            <WorkspaceMembersTable />
          ) : null}
          {!showMembersTable && showPendingInvites ? (
            <WorkspaceInvitesTable />
          ) : null}
        </Stack>
      </UserManagementContextProvider>
    </>
  );
}
