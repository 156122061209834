import { useElectric } from "@/electric/ElectricWrapper";
import { DirectWsInvitation, Feed } from "@/generated/client";
import { useLiveQuery } from "electric-sql/react";
import { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import accountInfo, { AccountInfo } from "../accountInfo";
import { WorkspaceContext } from "./workspaceProvider";
import React from "react";

export type FeedState = {
  currentFeedId?: string;
  currentFeed?: Feed;
  currentFeedAccounts?: Map<string, AccountInfo>;
  currentFeedPendingInvites?: DirectWsInvitation[];
  isCurrentFeedAdmin?: boolean;
  haveWritePermission?: boolean;
}

export const CurrentFeedContext = createContext<FeedState>({});


const CurrentFeedProvider = ({ children }) => {
  const { db } = useElectric();
  const { feedId: currentFeedId } = useParams();


  const { myCurrentWorkspaceMembership } = useContext(WorkspaceContext);

  const { results: currentFeed } = useLiveQuery(() => {
    if (!currentFeedId) return;
    return db.feed.liveUnique({
      where: {
        id: currentFeedId,
      },
    });
  }, [currentFeedId]);

  const { results: feedAccounts } = useLiveQuery(() => {
    if (!currentFeedId) return;
    return db.liveRaw({
      sql: `SELECT
						a.*,
						wshf.enabled as hands_free_enabled,
						wshf.timestamp as hands_free_timestamp
				FROM
					permission as p
				JOIN workspace_membership as wm
					ON wm.id = p.workspace_membershipId
				JOIN account as a 
					ON a.id = wm.accountId
				LEFT OUTER JOIN ws_hands_free_status as wshf ON wshf.id = p.workspace_membershipId
				WHERE
					p.feedId = ?
				AND
					p.enabled = 1
				AND
					wm.status = 'active'
				GROUP BY a.id
				ORDER BY LOWER(a.name) asc
				`,
      args: [currentFeedId],
    });
  }, [currentFeedId]);

  const currentFeedAccounts = useMemo(
    () => accountInfo(feedAccounts),
    [feedAccounts],
  );

  const { results: myFeedPermissions } = useLiveQuery(() => {
    if (!myCurrentWorkspaceMembership) return;
    return db.permission.liveMany({
      where: {
        enabled: 1,
        feedId: currentFeedId,
        workspace_membershipId: myCurrentWorkspaceMembership.id
      },
    });
  }, [myCurrentWorkspaceMembership, currentFeedId]);

  const isCurrentFeedAdmin = useMemo(
    () => myFeedPermissions?.some((p) => p.name === "admin"),
    [myFeedPermissions],
  );
  const haveWritePermission = useMemo(
    () => myFeedPermissions?.some((p) => p.name === "write"),
    [myFeedPermissions],
  );

  const { results: currentFeedPendingInvites } = useLiveQuery(() => {
    if (!currentFeedId) return;
    return db.liveRaw({
      sql: `SELECT
						dwi.*
				FROM
						permission as p
				JOIN direct_ws_invitation as dwi
					ON dwi.workspaceMembershipId = p.workspace_membershipId
				JOIN workspace_membership as wm
					ON wm.id = dwi.workspaceMembershipId
				WHERE
						p.feedId = ?
				AND
						p.enabled = 1
				AND
						dwi.claimedBy is null
				AND
						wm.status = 'active'
				GROUP BY
				p.workspace_membershipId`,
      args: [currentFeedId],
    });
  }, [currentFeedId]);




  return (
    <CurrentFeedContext.Provider value={{
      currentFeedId,
      currentFeed,
      currentFeedAccounts,
      currentFeedPendingInvites,
      isCurrentFeedAdmin,
      haveWritePermission,
    }}>
      {children}
    </CurrentFeedContext.Provider>
  );
}

export default CurrentFeedProvider;