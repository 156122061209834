import { useElectric } from "@/electric/ElectricWrapper";
import { Account } from "@/generated/client";
import { useLiveQuery } from "electric-sql/react";
import React, { useEffect } from "react";
import { createContext } from "react";

export type MyAccountState = {
  myAccount?: Account;
  myAccountId?: string;
}

export const MyAccountContext = createContext<MyAccountState>({});


const MyAccountProvider = ({ children }) => {
  const [myAccountId, setMyAccountId] = React.useState<string | undefined>(undefined);
  const { db } = useElectric();

  const { results: myAccount } = useLiveQuery(
    db.account.liveFirst({
      where: {
        mine: 1,
      },
    }),
  );

  useEffect(() => {
    if (myAccount) {
      setMyAccountId(myAccount.id);
    }
  }, [myAccount]);

  return (
    <MyAccountContext.Provider value={{ myAccountId, myAccount }}>
      {children}
    </MyAccountContext.Provider>
  );
}

export default MyAccountProvider;