import type React from "react";
import { Box, MenuItem } from "@mui/material";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Locator from "@/locator";
import ListIcon from "@/components/Icons/ListIcon";
import { useElectric } from "@/electric/ElectricWrapper";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import type { channelItem } from "@/components/WorkspaceChannelList/WorkspaceChannelListContainer";

interface Props {
  channel: channelItem;
  joined: boolean;
  workspaceMembershipId: string;
  handleClose: () => void;
  channelUrl: string;
}

export default function LinkContextMenu({
  channel,
  joined,
  workspaceMembershipId,
  handleClose,
  channelUrl,
}: Props) {
  // @todo: implement tracking for joining channels via context menus
  // const { ampli } = useContext(TrackingContext);
  const { fetchNextActiveChannel } = useContext(LiveQueryContext);
  const { joinPublicChannel, leavePublicChannel } = useContext(DataContext);
  const { accountEvent } = useContext(ActionContext);
  const { db } = useElectric();
  const { workspaceId, feedId } = useParams();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(false);

  const markChannelAsRead = async () => {
    accountEvent("Marked Feed as Read", {
      feedId: channel.id,
    });
    setDisabled(() => false);
    handleClose();
  };

  const joinChannel = async (id: string, redirect: boolean) => {
    setDisabled(() => true);
    await joinPublicChannel(workspaceId, id);
    handleClose();
    setDisabled(() => false);
    if (feedId !== id && redirect) {
      navigate(channelUrl);
    }
  };

  const leaveChannel = async () => {
    setDisabled(() => true);
    await leavePublicChannel(workspaceId, channel.id, workspaceMembershipId);
    const url = await fetchNextActiveChannel();
    handleClose();
    setDisabled(() => false);
    if (url && channel.id === feedId) {
      navigate(url);
    } else {
      navigate(`/workspaces/${workspaceId}`);
    }
  };

  const markAllAsRead = async () => {
    setDisabled(() => true);

    const sql = `
    select
     item.id, item.feedId, item.groupId, item.unread,
     feed.title,
     feed_group_membership.groupId as feedGroupId,
     permission.workspace_membershipId as joined
     from item
     join feed on feed.id = item.feedId
     JOIN permission  ON feed.id = permission.feedId AND permission.workspace_membershipId = $1 AND permission.name = 'read' AND permission.enabled = true
     LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
     LEFT JOIN feed_group on feed_group.id = feed_group_membership.groupId
     where
     unread = 1
     AND feed_group_membership.groupId IS NULL
     GROUP by item.id
    `;
    const feedItems = await db.raw({
      sql,
      args: [workspaceMembershipId],
    });
    if (feedItems.length > 0) {
      for (const feedItem of feedItems) {
        accountEvent("Marked Feed as Read", {
          feedId: feedItem.feedId,
        });
      }
    }

    setDisabled(() => false);
    handleClose();
  };

  return (
    <div className="new-channel-item-context-menu">
      {channel?.unread === 1 && channel?.joined && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.markAsRead}
          disabled={disabled}
          onClick={markChannelAsRead}
        >
          <Box className="new-channel-context-button">
            <DraftsOutlinedIcon />
          </Box>
          <Box>Mark As Read</Box>
        </MenuItem>
      )}
      {channel?.unread === 1 && channel?.joined && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.markAllAsRead}
          disabled={disabled}
          onClick={markAllAsRead}
        >
          <Box className="new-channel-context-button">
            <ListIcon />
          </Box>
          <Box>Mark all as Read</Box>
        </MenuItem>
      )}
      {joined ? (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.leave}
          disabled={disabled}
          onClick={leaveChannel}
        >
          <Box className="new-channel-context-button">
            <LogoutIcon />
          </Box>
          <Box>Leave Channel</Box>
        </MenuItem>
      ) : (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.join}
          disabled={disabled}
          onClick={() => joinChannel(channel.id, false)}
        >
          <Box className="new-channel-context-button">
            <LoginIcon />
          </Box>
          <Box>Join Channel</Box>
        </MenuItem>
      )}
    </div>
  );
}
