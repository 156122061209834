import { Ampli } from "@/ampli";
import { upsertMyAccount } from "@/data/oldWorld";
import {
  createManyWorkspace,
  createManyWorkspaceMembership,
  createManyWsAccount,
  upsertDeviceRegistration,
  upsertDirectWsInvitation,
  upsertManyWsPermission,
  upsertWorkspace,
  upsertWorkspaceMembership,
  upsertWsAccount,
  upsertWsAudioEncoding,
  upsertWsBroadcastAction,
  upsertWsBroadcastRecipient,
  upsertWsCallRecord,
  upsertWsCommandAlias,
  upsertWsDisplayArtifact,
  upsertWsDraft,
  upsertWsEvent,
  upsertWsFeed,
  upsertWsFeedGroup,
  upsertWsFeedGroupMembership,
  upsertWsFile,
  upsertWsHandsFreeStatus,
  upsertWsItem,
  upsertWsLink,
  upsertWsPAM,
  upsertWsPermission,
  upsertWsPublishedDraft,
  upsertWsScheduleTrigger,
  upsertWsTemplate,
  upsertWsTranscription,
} from "@/data/workspace";
import { handsFreeEnabledForWorkspace } from "@/data/workspaceConfig";
import { useElectric } from "@/electric/ElectricWrapper";
import {
  DirectWsInvitation,
  Electric,
  Feed,
  Permission,
  WorkspaceMembership
} from "@/generated/client";
import UseStorage from "@/hooks/useStorage";
import BootstrapApplication from "@/models/BootstrapApplication";
import { useBootTimesStore } from "@/stores/useBootTimesStore";
import { type MappedWorkspaceRole, buildVersion } from "@/utils";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import * as Sentry from "@sentry/browser";
import { useLocalStorage, useNetworkState } from "@uidotdev/usehooks";
import cuid from "cuid";
import { useLiveQuery } from "electric-sql/react";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  AppContext as ApplicationContext,
  DeviceContext,
  DeviceRegistration,
  UserInfoResponse,
  Workspace,
  WsAppSyncEvent,
  WsEvent,
  WsItem
} from "web-client/api/data-contracts";
import Client from "web-client/client";
import { AppContext } from "./AppStateProvider";
import { MyAccountContext } from "./StateProviders/myAccountProvider";
import { TrackingContext } from "./TrackingStateProvider";
import { UnreadsContext } from "./UnreadsContextProvider";
import accountInfo, { AccountInfo } from "./accountInfo";
import {
  downloadPaginatedBootstrapFeedItems,
  getNextFeedItemPage,
  initialFeedLoad,
} from "./actions/initialFeedLoad";
import { LANGUAGE_LIST, PreferredLanguage } from "./languages";
import { WorkspaceContext } from "./StateProviders/workspaceProvider";
import { CurrentFeedContext } from "./StateProviders/currentFeedProvider";

declare const window: Window & { dataLayer: Record<string, unknown>[] };

export const ITEMS_PER_PAGE = 10;
let subscription: any;

const LANGUAGE_SESSION_KEY = "preferredLanguage";
const LANGUAGE_DEFAULT_VALUE = LANGUAGE_LIST[0] ?? "none";

function appSyncSubscriptionUpdate(
  db: Electric["db"],
  event: WsAppSyncEvent,
  handleUnreadItem: (items: WsItem, myAccountId: string) => Promise<void>,
  handleReadItemEvent: (events: WsEvent, myAccountId: string) => Promise<void>,
  client: Client,
  myAccountId: string,
) {
  console.log("app sync", event);
  for (const i of event?.accounts || []) {
    upsertWsAccount(db, i);
  }
  for (const i of event?.workspaces || []) {
    upsertWorkspace(db, i);
  }
  for (const i of event?.workspaceMemberships || []) {
    upsertWorkspaceMembership(db, i);
  }
  for (const i of event?.directWorkspaceInvitations || []) {
    upsertDirectWsInvitation(db, i);
  }
  for (const i of event?.feeds || []) {
    upsertWsFeed(db, i);
  }
  for (const i of event?.feedGroups || []) {
    upsertWsFeedGroup(db, i);
  }
  for (const i of event?.feedGroupMemberships || []) {
    upsertWsFeedGroupMembership(db, i);
  }
  for (const i of event?.permissions || []) {
    upsertWsPermission(db, i);
  }
  for (const i of event?.items || []) {
    upsertWsItem(db, i);
    client.createContentReceipt({
      contentId: i.contentId,
      artifactId: i.id,
      artifactType: "item",
    });
  }
  for (const i of event?.audioEncodings || []) {
    upsertWsAudioEncoding(db, i);
    client.createContentReceipt({
      contentId: i.contentId,
      artifactId: i.id,
      artifactType: "audioEncoding",
    });
  }
  for (const i of event?.displayArtifacts || []) {
    upsertWsDisplayArtifact(db, i);
    client.createContentReceipt({
      contentId: i.contentId,
      artifactId: i.id,
      artifactType: "displayArtifact",
    });
  }
  for (const i of event?.files || []) {
    upsertWsFile(db, i);
    client.createContentReceipt({
      contentId: i.contentId,
      artifactId: i.id,
      artifactType: "file",
    });
  }
  for (const i of event?.links || []) {
    upsertWsLink(db, i);
    client.createContentReceipt({
      contentId: i.contentId,
      artifactId: i.id,
      artifactType: "link",
    });
  }
  for (const i of event?.transcriptions || []) {
    upsertWsTranscription(db, i);
    client.createContentReceipt({
      contentId: i.contentId,
      artifactId: i.id,
      artifactType: "transcription",
    });
  }
  for (const i of event?.callRecords || []) {
    upsertWsCallRecord(db, i);
    client.createContentReceipt({
      contentId: i.contentId,
      artifactId: i.id,
      artifactType: "callRecord",
    });
  }
  for (const i of event?.events || []) {
    upsertWsEvent(db, i);
    if (i.contentId) {
      // client.createContentReceipt({
      // 	contentId: i.contentId,
      // 	artifactId: i.id,
      // 	artifactType: "accountEvent",
      // });
    }
  }
  for (const i of event?.commandAliases || []) {
    upsertWsCommandAlias(db, i);
  }

  for (const i of event?.workflowItems || []) {
    upsertWsDraft(db, i);
  }

  for (const i of event?.scheduleTriggers || []) {
    upsertWsScheduleTrigger(db, i);
  }

  for (const i of event?.broadcastActions || []) {
    upsertWsBroadcastAction(db, i);
  }

  for (const i of event?.publishedWorkflowItems || []) {
    upsertWsPublishedDraft(db, i);
  }

  for (const i of event?.broadcastRecipients || []) {
    upsertWsBroadcastRecipient(db, i);
  }
  for (const i of event?.pam || []) {
    upsertWsPAM(db, i);
  }
  for (const i of event?.templates || []) {
    upsertWsTemplate(db, i);
  }
  for (const i of event?.deviceRegistrations || []) {
    upsertDeviceRegistration(db, i);
  }

  if (event?.items?.length > 0) {
    for (const i of event.items) {
      handleUnreadItem(i, myAccountId);
    }
  }
  if (event?.events?.length > 0) {
    for (const e of event.events) {
      handleReadItemEvent(e, myAccountId);
    }
  }
}

export type DataState = {
  appContext?: ApplicationContext;
  availableWorkspaceRoles?: Map<string, MappedWorkspaceRole>;
  getWorkspaceRoleLabel?: (role: string) => string;
  deviceContext?: DeviceContext;
  bootstrapComplete?: boolean;
  loadFeed?: (feedId: string) => Promise<boolean>;
  loadNextFeedItemPage?: (feedId: string) => Promise<number>;
  loadWorkspaceWorkflowItems?: (workspaceId: string) => void;
  preferredLanguage: PreferredLanguage;
  setPreferredLanguage?: (language: PreferredLanguage) => void;
  fetchWorkspaceMembership?: (
    workspaceMembershipId: string,
    feedId: string,
  ) => Promise<WorkspaceMembership>;
  listFeedPermissions?: (
    workspaceMembershipId: string,
    feedId: string,
  ) => Promise<Array<Permission>>;
  joinPublicChannel?: (workspaceId: string, feedId: string) => Promise<boolean>;
  leavePublicChannel?: (
    workspaceId: string,
    feedId: string,
    membershipId: string,
  ) => Promise<boolean>;
  joinFeedGroup?: (params: {
    workspaceId: string;
    feedGroupId: string;
  }) => Promise<boolean>;
  leaveFeedGroup?: (params: {
    workspaceId: string;
    feedGroupId: string;
  }) => Promise<boolean>;
  fetchWorkspaceHandsFreeStatus?: () => Promise<void>;
  fetchGeoCoords?: (
    workspaceId: string,
    membershipIds: string[],
  ) => Promise<{ lat: number; lng: number } | null>;
  fetchSingleFeed?: (feedId: string) => Feed | null;
};

//create a context, with createContext api
export const DataContext = createContext<DataState>({
  preferredLanguage: "en",
});

type Props = {
  children: React.ReactNode | React.ReactNode[];
  client: Client;
};

const DataProvider = ({ children, client }: Props) => {
  const { pubSub, flags } = React.useContext(AppContext);
  const { handleUnreadItem, handleReadItemEvent } =
    React.useContext(UnreadsContext);
  const { limitedMemberRole, trackBootTimes, amplitudeSessionTracking } =
    useFlags();
  const ldClient = useLDClient();
  const bootTimesStore = useBootTimesStore();
  const { ampli }: { ampli: Ampli } = React.useContext(TrackingContext);
  useEffect(() => {
    bootTimesStore.injectAmplitude(ampli);
    bootTimesStore.setLogBootTimes(trackBootTimes);
    bootTimesStore.addBootTime({ event: "BootApplication", name: "start" });
    bootTimesStore.addBootTime({ event: "TTSFooter", name: "start" });
    bootTimesStore.addBootTime({
      event: "WorkspaceFeedChannelList",
      name: "start",
    });
  }, []);

  const { currentFeedId } = useContext(CurrentFeedContext)

  const [bootstrapComplete, setBootstrapComplete] = useState<boolean>(false);
  const [user, setUser] = useState<UserInfoResponse | null>(null);

  const { getLocalStorage, setLocalStorage } = UseStorage();

  const { db, adapter } = useElectric();

  const { myAccount } = React.useContext(MyAccountContext);
  const { currentWorkspaceId, workspaces } = React.useContext(WorkspaceContext);



  const localStoragePreferredLanguage = getLocalStorage(
    "preferredLanguage",
  ) as PreferredLanguage;

  const preferredLanguage =
    localStoragePreferredLanguage || LANGUAGE_DEFAULT_VALUE;

  const setPreferredLanguage = useCallback(
    (language: PreferredLanguage) => {
      if (!myAccount?.id) return;
      db.account
        .update({
          where: {
            id: myAccount.id,
          },
          data: {
            preferredLanguage: language,
          },
        })
        .then(() => {
          setLocalStorage({ key: LANGUAGE_SESSION_KEY, value: language });
        });
    },
    [db, myAccount?.id, setLocalStorage],
  );


  const applicationContext = useMemo(() => {
    return {
      handsFreeEnabled: handsFreeEnabledForWorkspace(currentWorkspaceId),
      selectedWorkspaceId: currentWorkspaceId,
      workspaces:
        workspaces?.map((w) => {
          return {
            handsFreeEnabled: handsFreeEnabledForWorkspace(w?.id) || false,
            workspaceId: w?.id || "",
            feedSelected: w?.id === currentWorkspaceId ? currentFeedId : null,
            languageSelected: preferredLanguage,
          };
        }) || [],
    } as ApplicationContext;
  }, [currentWorkspaceId, workspaces, currentFeedId, preferredLanguage]);

  const [deviceRegistrationId] = useLocalStorage("deviceRegistrationId", "");
  const deviceContext = useMemo(() => {
    return {
      surface: "web",
      surfaceContext: window?.navigator?.userAgent,
      surfaceBuild: buildVersion(),
      notificationsEnabled: Notification?.permission === "granted",
      internetConnectionStatus: "online",
      deviceLanguage: window?.navigator?.language || preferredLanguage,
      deviceRegistrationId: deviceRegistrationId,
    } as DeviceContext
  }, [deviceRegistrationId, preferredLanguage]);


  // setup mapped workspace roles from api -> client label
  const availableWorkspaceRoles = new Map<string, MappedWorkspaceRole>([
    ["admin", { role: "admin", label: "Administrator", enabled: true }],
    ["member", { role: "member", label: "Organizer", enabled: true }],
    [
      "limitedMember",
      {
        role: "limitedMember",
        label: "Member",
        enabled: limitedMemberRole,
      },
    ],
  ]);

  const getWorkspaceRoleLabel = (role: string) => {
    return availableWorkspaceRoles?.get(role)?.label || role;
  };

  const subscribeToAppSync = React.useCallback(
    (accountIds: string[], workspaceIds: string[]) => {
      const accountId = accountIds[0];
      if (!pubSub || !client) return;

      const channels: string[] = [];
      for (const accountId of accountIds) {
        channels.push(`wsaccount#${accountId}`);
      }
      if (workspaceIds?.length > 0) {
        for (const wid of workspaceIds) {
          channels.push(`workspace#${wid}`);
        }
      }
      console.log("Subscribing to ws account and workspaces", channels);
      const newSub = pubSub.subscribeFilter(
        channels,
        (data?: any) => {
          if (data?.data) {
            const eventData = JSON.parse(data.data) as WsAppSyncEvent;
            appSyncSubscriptionUpdate(
              db,
              eventData,
              handleUnreadItem,
              handleReadItemEvent,
              client,
              accountId,
            );
          } else {
            console.log(
              "Account Feed Subscription Event called with no data",
              data,
            );
          }
        },
        (e: any) => {
          console.error("Account Feed Subscription Error", e);
        },
      );
      subscription?.unsubscribe();
      subscription = newSub;
    },
    [
      pubSub,
      db,
      handleUnreadItem,
      handleReadItemEvent,
      client,
    ],
  );

  useEffect(() => {
    const validateUser = async () => {
      try {
        bootTimesStore.addBootTime({ event: "ValidateUser", name: "start" });
        const userInfoResponse = await client.getUserInfo();

        if (userInfoResponse?.workspaces?.length > 0) {
          await createManyWorkspace(db, userInfoResponse?.workspaces || []);
        }
        if (userInfoResponse?.workspaceMemberships?.length > 0) {
          await createManyWorkspaceMembership(
            db,
            userInfoResponse?.workspaceMemberships || [],
          );
        }
        if (userInfoResponse?.accounts?.length > 0) {
          await createManyWsAccount(db, userInfoResponse?.accounts || []);
        }
        setUser((prev) =>
          JSON.stringify(prev) !== JSON.stringify(userInfoResponse)
            ? userInfoResponse
            : prev,
        );
        bootTimesStore.addBootTime({ event: "ValidateUser", name: "finish" });
        const sessionStorageValue =
          window?.sessionStorage?.getItem(LANGUAGE_SESSION_KEY);
        if (sessionStorageValue) {
          console.log("FOUND SESSION STORAGE VALUE", sessionStorageValue);
        } else {
          console.log("NO SESSION STORAGE VALUE");
        }
        const account = await upsertMyAccount(db, userInfoResponse);
        if (account) {
          if (!account?.preferredLanguage) {
            await db.account.update({
              where: {
                id: account.id,
              },
              data: {
                preferredLanguage: sessionStorageValue || "none",
              },
            });
          }
          // initial subscription
          subscribeToAppSync([account.id], currentWorkspaceId ? [currentWorkspaceId] : []);
          ampli.client.setUserId(account.id);

          Sentry.setUser({ accountId: account.id });
          ampli.authSuccess({ "Auth0 App": "SMS" });
          ldClient
            .identify({
              kind: "user",
              key: account.id,
              name: account?.name,
              email: account?.email,
              sbAccount: account?.email?.includes("@storyboard.fm"),
            })
            .catch((e) => {
              console.error(e);
            });
          if (window.dataLayer) {
            window.dataLayer.push({
              event: {
                name: "authSuccess",
                value: "Auth0 App: SMS",
              },
            });
          }
        }
      } catch (e) {
        const maintenancePage =
          window.location.pathname.includes("maintenance-mode");
        if (e?.status === 418 && !maintenancePage) {
          window.location.href = "/maintenance-mode";
        }
        return Promise.reject(e);
      }
    };
    validateUser();
  }, [subscribeToAppSync]);

  useEffect(() => {
    if (amplitudeSessionTracking) {
      if (amplitudeSessionTracking) {
        const sessionReplayTracking = sessionReplayPlugin({
          sampleRate: 0.3,
        });
        amplitude.add(sessionReplayTracking);
      }
    }
  }, [amplitudeSessionTracking]);

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string | null>(
    null,
  );
  const { allComplete, bootstrapPaginatedApplication } = BootstrapApplication();

  let localWorkspaceId = null;
  useEffect(() => {
    bootTimesStore.addBootTime({
      event: "BootApplication",
      name: "start-boot",
    });
    if (
      selectedWorkspaceId &&
      currentWorkspaceId &&
      selectedWorkspaceId !== currentWorkspaceId
    ) {
      localWorkspaceId = currentWorkspaceId;
    }

    if (!currentWorkspaceId && !selectedWorkspaceId && user?.workspaces?.length > 0) {
      localWorkspaceId = user.workspaces[0].id;
    }

    if (currentWorkspaceId && !selectedWorkspaceId) {
      localWorkspaceId = currentWorkspaceId;
    }
    // resubscribe to app sync
    subscribeToAppSync(
      [user?.session?.credentials?.[0].CredentialScopes?.[0].accountId],
      localWorkspaceId ? [localWorkspaceId] : [currentWorkspaceId || ""],
    );

    if (localWorkspaceId) {
      bootstrapPaginatedApplication({
        client,
        db,
        user,
        workspaceId: localWorkspaceId,
        forceBootstrap: true, //params.workspaceId !== localWorkspaceId,
      }).then(() => {
        setSelectedWorkspaceId(() => localWorkspaceId);
        localWorkspaceId = null;
        bootTimesStore.addBootTime({
          event: "BootApplication",
          name: "complete-async-boot",
        });
      });
    } else if (user?.session?.credentials?.[0]?.id && !localWorkspaceId) {
      client
        .bootstrapWorkspaces()
        .then((data) => setBootstrapComplete(() => true));
    }
  }, [
    user?.session?.credentials?.[0]?.id,
    localWorkspaceId,
    currentWorkspaceId,
    selectedWorkspaceId,
  ]);

  useEffect(() => {
    if (allComplete) {
      setBootstrapComplete(() => allComplete);
      bootTimesStore.addBootTime({
        event: "BootApplication",
        name: "all-complete",
      });
    }
  }, [allComplete]);



  const loadWorkspaceWorkflowItems = React.useCallback(
    async (workspaceId: string) => {
      if (!myAccount?.id) return;
      await client.getWorkspaceWorkflowItems(workspaceId).then((resp) => {
        console.log("Workspace workflow items response", { resp });
        for (const workflowItem of resp?.workflowItems || []) {
          upsertWsDraft(db, workflowItem);
        }
        // load associated content of the workflow items
        for (const i of resp?.audioEncodings || []) {
          upsertWsAudioEncoding(db, i);
        }
        for (const i of resp?.callRecords || []) {
          upsertWsCallRecord(db, i);
        }
        for (const i of resp?.files || []) {
          upsertWsFile(db, i);
        }
        for (const i of resp?.links || []) {
          upsertWsLink(db, i);
        }
        for (const i of resp?.transcriptions || []) {
          upsertWsTranscription(db, i);
        }
        for (const i of resp?.displayArtifacts || []) {
          upsertWsDisplayArtifact(db, i);
        }
      });

      await client.getScheduledBroadcasts(workspaceId).then((resp) => {
        console.log("Workspace scheduled broadcasts response", { resp });
        for (const scheduleTrigger of resp?.scheduleTriggers || []) {
          upsertWsScheduleTrigger(db, scheduleTrigger);
        }
        for (const broadcastAction of resp?.broadcastActions || []) {
          upsertWsBroadcastAction(db, broadcastAction);
        }
        for (const broadcastRecipient of resp?.broadcastRecipients || []) {
          upsertWsBroadcastRecipient(db, broadcastRecipient);
        }
      });
    },
    [client, db, myAccount?.id],
  );

  const fetchWorkspaceMembership = async (
    accountId: string,
    workspaceId: string,
  ) => {
    const workspaceMembership = await db.workspace_membership.findFirst({
      where: {
        accountId,
        workspaceId,
        status: "active",
      },
    });
    if (!workspaceMembership) {
      return Promise.reject("Invalid Workspace");
    }
    return workspaceMembership;
  };


  const listFeedPermissions = React.useCallback(
    async (
      workspaceMembershipId: string,
      feedId: string,
    ): Promise<Array<Permission>> => {
      try {
        return await db.permission.findMany({
          where: {
            feedId,
            workspace_membershipId: { in: [workspaceMembershipId] },
          },
        });
      } catch (e) {
        return [];
      }
    },
    [db],
  );

  const joinPublicChannel = async (
    workspaceId: string,
    feedId: string,
  ): Promise<boolean> => {
    const permissions = await client.joinPublicFeed(workspaceId, feedId);
    for (const p of permissions) {
      upsertWsPermission(db, p);
    }
    // after joining the feed, download the feed items
    await downloadPaginatedBootstrapFeedItems(client, db, workspaceId);
    return true;
  };

  const leavePublicChannel = async (
    workspaceId: string,
    feedId: string,
    membershipId: string,
  ): Promise<boolean> => {
    await client.unSubscribeFromWorkspaceFeed(workspaceId, feedId);
    await db.permission.deleteMany({
      where: {
        feedId,
        workspace_membershipId: { in: [membershipId] },
      },
    });
    return true;
  };

  const leaveFeedGroup = async ({
    workspaceId,
    feedGroupId,
  }: { workspaceId: string; feedGroupId: string }): Promise<boolean> => {
    const response = await client.leaveFeedGroup({ workspaceId, feedGroupId });
    const permissions = response?.permissions || [];
    await upsertManyWsPermission(db, permissions, adapter);
    return true;
  };

  const joinFeedGroup = async ({
    workspaceId,
    feedGroupId,
  }: { workspaceId: string; feedGroupId: string }): Promise<boolean> => {
    const response = await client.joinFeedGroup({ workspaceId, feedGroupId });
    const permissions = response?.permissions || [];
    await upsertManyWsPermission(db, permissions, adapter);
    await downloadPaginatedBootstrapFeedItems(client, db, workspaceId);
    return true;
  };

  const fetchWorkspaceHandsFreeStatus = async (): Promise<void> => {
    const allWorkspaceMemberships = await db.workspace_membership.findMany({
      where: {
        status: "active",
        workspaceId: currentWorkspaceId,
      },
    });
    const handsFreeStatusResponse = await client.getHandsFreeStatus(
      currentWorkspaceId,
      {
        membershipIds: allWorkspaceMemberships.map((m) => m.id),
      },
    );

    if (!handsFreeStatusResponse?.data) return;

    const { data } = handsFreeStatusResponse;

    const checkTimestampAgainstDate = (timestamp: string): boolean => {
      const now = new Date();
      const nowGmt = now.toUTCString();
      const gmt = new Date(timestamp).toUTCString();
      const nowTime = new Date(nowGmt).getTime();
      const timestampTime = new Date(gmt).getTime();
      const diff = nowTime - timestampTime;
      const millisecondsInMinute: number = 60000;
      const minutes: number = 11;
      const threshold: number = minutes * millisecondsInMinute;
      return diff >= threshold;
    };

    const mappedResponse: Array<{
      id: string;
      enabled: number;
      timestamp: string;
    }> = data
      ?.filter((item) => item?.statuses[0]?.timestamp)
      ?.map((item) => {
        const staleTimestamp = checkTimestampAgainstDate(
          item.statuses[0].timestamp,
        );
        const enabled = item.statuses[0].enabled ? 1 : 0;
        return {
          id: item.membershipId,
          enabled: staleTimestamp === true ? 0 : enabled,
          timestamp: item.statuses[0].timestamp,
        };
      })
      ?.sort((a, b) => b.enabled - a.enabled);

    if (mappedResponse?.length > 0) {
      for (const status of mappedResponse) {
        upsertWsHandsFreeStatus(db, status);
      }
    }
  };

  const fetchGeoCoords = async (
    workspaceId: string,
    membershipIds: string[],
  ): Promise<{ lat: number; lng: number } | null> => {
    const { data } = await client.getLocations(workspaceId, { membershipIds });
    return data[0]?.locations[0]?.latitude
      ? {
        lat: data[0]?.locations[0]?.latitude,
        lng: data[0]?.locations[0]?.longitude,
      }
      : null;
  };

  const fetchSingleFeed = (feedId: string): Feed | null => {
    const { results: feed } = useLiveQuery(() => {
      if (!feedId) return;
      return db.feed.liveFirst({
        where: {
          id: feedId,
        },
      });
    }, [feedId]);
    return feed?.id ? feed : null;
  };


  useEffect(() => {
    const f = async () => {
      try {
        let deviceId = deviceRegistrationId;
        if (!deviceId) {
          deviceId = cuid();
        }
        const newDeviceReg = {
          id: deviceId,
          surface: "web",
        } as DeviceRegistration;
        const deviceRegistration = await client.registerDevice({
          deviceRegistration: newDeviceReg,
        });
        if (deviceRegistration?.id) {
          // save to local storage
          await upsertDeviceRegistration(db, deviceRegistration);
        }
      } catch (error) {
        console.error("Error registering device", error);
      }
    };
    f();
  }, []);


  // const applicationContext =
  //   {
  //     handsFreeEnabled: handsFreeEnabledForWorkspace(currentWorkspaceId),
  //     selectedWorkspaceId: currentWorkspaceId,
  //     workspaces:
  //       workspaces?.map((w) => {
  //         return {
  //           handsFreeEnabled: handsFreeEnabledForWorkspace(w?.id) || false,
  //           workspaceId: w?.id || "",
  //           feedSelected: w?.id === currentWorkspaceId ? currentFeedId : null,
  //           languageSelected: preferredLanguage,
  //         };
  //       }) || [],
  //   } as ApplicationContext;

  // const deviceContext = {
  //   surface: "web",
  //   surfaceContext: window?.navigator?.userAgent,
  //   surfaceBuild: buildVersion(),
  //   notificationsEnabled: Notification?.permission === "granted",
  //   internetConnectionStatus: useNetworkState()?.online ? "online" : "offline",
  //   deviceLanguage: window?.navigator?.language || preferredLanguage,
  //   deviceRegistrationId: deviceRegistrationId,
  // } as DeviceContext

  const dataState: DataState = {
    appContext: applicationContext,
    availableWorkspaceRoles,
    getWorkspaceRoleLabel,
    joinPublicChannel,
    leavePublicChannel,
    joinFeedGroup,
    leaveFeedGroup,
    deviceContext,
    bootstrapComplete,
    preferredLanguage,
    setPreferredLanguage: setPreferredLanguage,
    fetchWorkspaceMembership,
    listFeedPermissions,
    fetchSingleFeed,
    fetchWorkspaceHandsFreeStatus,
    fetchGeoCoords,
    loadFeed: (feedId: string) => {
      return initialFeedLoad(client, db, feedId);
    },
    loadNextFeedItemPage: async (feedId: string) => {
      return getNextFeedItemPage(client, db, feedId);
    },
    loadWorkspaceWorkflowItems,
  };
  return (
    <DataContext.Provider value={dataState}>{children}</DataContext.Provider>
  );
};
export default DataProvider;
