import UseContextMenu from "@/components/ContextMenus/UseContextMenu";
import LockIcon from "@mui/icons-material/Lock";
import { Box, useTheme } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useContext, useState } from "react";
import { DataContext } from "@/models/DataProvider";
import SteeringWheel from "@/components/Icons/SteeringWheel";
import type { channelItem } from "@/components/WorkspaceChannelList/WorkspaceChannelListContainer";
import LinkContextMenu from "@/components/WorkspaceChannelList/LinkContextMenu";
import Locator from "@/locator";

interface LinkProps {
  channel: channelItem;
  active: boolean;
  joined: boolean;
  workspaceMembershipId: string;
  clickUnreadItem: (id: string | null) => void;
}

export default function WorkspaceChannelLinkItem({
  clickUnreadItem,
  channel,
  active,
  joined,
  workspaceMembershipId,
}: LinkProps) {
  const theme = useTheme();
  const { handleContextMenu, handleClose, contextMenuItem, contextMenu } =
    UseContextMenu();
  const { joinPublicChannel } = useContext(DataContext);
  const { workspaceId, feedId } = useParams();
  const navigate = useNavigate();
  const channelUrl = `/workspaces/${channel.workspaceId}/feeds/${channel.id}`;
  const [disabled, setDisabled] = useState<boolean>(false);

  const joinChannel = async (id: string, redirect: boolean) => {
    setDisabled(() => true);
    await joinPublicChannel(workspaceId, id);
    handleClose();
    setDisabled(() => false);
    if (feedId !== id && redirect) {
      navigate(channelUrl);
    }
  };

  return (
    <Link
      data-testid={Locator.workspaceNav.channels.list.testId(channel.id)}
      aria-label={Locator.workspaceNav.channels.list.item}
      to={`${disabled ? "#" : channelUrl}`}
      onContextMenu={handleContextMenu}
      className={`
        workspace-channel-item
        ${active ? "active" : ""}
        ${joined ? "joined" : "not-joined"}
        ${disabled ? "disabled" : ""}
      `}
      onClick={async (e) => {
        if (channel.unread) {
          clickUnreadItem(channel.id);
        }
        if (contextMenu) {
          e.preventDefault();
          return;
        }
        if (!channel?.joined) {
          clickUnreadItem(null);
          e.preventDefault();
          await joinChannel(channel.id, true);
        }
      }}
    >
      {channel?.isPrivate === 1 && !channel?.aliasChannel && (
        <Box className="workspace-channel-item-lock">
          <LockIcon sx={{ fontSize: "1.125rem" }} />
        </Box>
      )}
      {channel?.aliasChannel && channel?.isPrivate === 0 && (
        <Box className="workspace-channel-item-lock">
          <SteeringWheel />
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }}>{channel.title}</Box>
      {channel?.unread === 1 && (
        <Box
          sx={{
            background: channel?.joined
              ? theme.palette.brand.secondary.main
              : theme.palette.secondary.main,
            width: "14px",
            height: "14px",
            borderRadius: "100%",
          }}
        />
      )}
      {contextMenuItem(
        <LinkContextMenu
          channel={channel}
          joined={joined}
          workspaceMembershipId={workspaceMembershipId}
          channelUrl={channelUrl}
          handleClose={handleClose}
        />,
      )}
    </Link>
  );
}
